<script>
import gsap from "gsap";

export default {
  name: "LogoPin",
  props: ["logo"],
  methods: {
    over(e) {
      gsap.set(e.currentTarget, {
        zIndex: 10,
        transformOrigin: "50% 0",
      });
      gsap.to(e.currentTarget, {
        duration: 0.2,
        scale: 1.1,
        ease: "back.out(1.5)",
      });
    },
    out(e) {
      gsap.set(e.currentTarget, {
        zIndex: 9,
        transformOrigin: "50% 0",
      });
      gsap.to(e.currentTarget, {
        duration: 0.2,
        scale: 1,
      });
    },
    click(e) {
      this.$emit("click", { e, link: this.logo.value.link_url });
    },
  },
};
</script>

<template>
  <div
    class="pin LOGO-PIN"
    @mouseenter="over"
    @mouseleave="out"
    @click="click"
    :style="{
      top: `${logo.value.y}%`,
      left: `${logo.value.x}%`,
      width: `${logo.value.logo_width}%`,
      transform: `skewY(${logo.value.logo_skew}deg)`,
    }"
  >
    <img :alt="logo.value.logo_alt ? logo.value.logo_alt : '' " :src="getMedia(logo.value.logo_image.path)" />
  </div>
</template>

<style scoped lang="scss">
@function get-vw($target) {
  $vw-context: (1000 * 0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
.LOGO-PIN {
  position: absolute;
  background-color: white;
  cursor: pointer;
  // transform: skewY(-12deg);
  img {
    display: block;
    width: 100%;
  }
}

.pin:hover {
  z-index: 100;
}
</style>
