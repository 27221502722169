<script>
import gsap from "gsap";
import { mapState, mapActions } from "vuex";
import Pin from "@/components/Pin.vue";
import MapLegend from "@/components/Legend.vue";
import Heading from "@/components/Heading.vue";
import LogoPin from "@/components/pins/Logo.vue";
import router from "../router";

export default {
  name: "Map",
  data() {
    return {
      currentMapImg: "",
      pins: [],
      previewMapImg: "",
      legend: [],
      legendTitle: "Categories",
      vendorLogos: [],
      mapAttr: {},
      nextMap: { background_image: { path: "" } },
      windowSize: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
      mapArea: {
        width: "100%",
        height: "57vw",
        minWidth: "none",
        maxWidth: "none",
        maxHeight: "none",
        overflow: "visible",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      mapContainer: {
        overflowX: "scroll",
        overflowY: "scroll",
        backgroundSize: "195.2%",
      },
      asycImg: new Image(),
      imageState: "loading",
    };
  },
  props: ["name", "isDefault"],
  components: {
    Pin,
    Heading,
    MapLegend,
    LogoPin,
  },
  created() {
    this.buildMap();
  },
  watch: {
    $route() {
      this.buildMap();
    },
    "windowSize.height": function() {
      this.mapSize();
    },
    "windowSize.width": function() {
      this.mapSize();
    },
    currentMapImg() {
      this.asycImg.src = this.currentMapImg;
    },
    "imageState": function(){
      if (this.imageState == "loaded" ){
        this.windowResize();
        this.mapSize();
      }
    }
  },
  computed: {
    ...mapState(["currentMap", "maps"]),
  },
  methods: {
    ...mapActions(["SET_BREADCRUMBS"]),
    setPreviewMapImg() {
      if (this.currentMap && this.currentMap.lores_preview) {
        this.previewMapImg = this.getMedia(this.currentMap.lores_preview.path);
      } else {
        this.previewMapImg = "";
      }
    },
    setCurrentMapImg() {
      if (this.currentMap && this.currentMap.background_image) {
        this.currentMapImg = this.getMedia(
          this.currentMap.background_image.path
        );
      } else {
        this.currentMapImg = "";
      }
    },
    setPins() {
      if (this.currentMap && this.currentMap.map_pins) {
        const thismaps = this.currentMap.map_pins.map((x) => x.value);
        this.pins = thismaps;
      } else {
        this.pins = [];
      }
    },
    setLegend() {
      if (this.currentMap && this.currentMap.legend_pins) {
        const legendpins = this.currentMap.legend_pins.map((x) => x.value);
        this.legend = legendpins;
      } else {
        this.legend = [];
      }
    },
    setLegendTitle() {
      if (this.currentMap.legend_title) {
        this.legendTitle = this.currentMap.legend_title;
      } 
    },
    setVendorLogos() {
      if (this.currentMap && this.currentMap.vendor_logos) {
        this.vendorLogos = this.currentMap.vendor_logos;
      } else {
        this.vendorLogos = [];
      }
    },
    setMapAttr() {
      if (this.$refs.map) {
        this.mapAttr = this.$refs.map.clientWidth;
      } else {
        this.mapAttr = {};
      }
    },
    fetchImage() {
      this.asycImg.onload = this.mapLoaded;
      this.asycImg.src = this.currentMapImg;
      // this.mapSize();
    },
    mapLoaded() {
      this.imageState = "loaded";
    },
    windowResize() {
      this.windowSize.height = window.innerHeight;
      this.windowSize.width = window.innerWidth;
    },
    mapSize() {
      if (document.getElementById("map")) {
        // OUTER MAP ELEMENT
        let mapEl = document.getElementById("map");
        // INNER MAP ELEMENT
        let areaEl = document.getElementById("map-area");

        // Isn't "this.mapArea" the same as "areaEl"?
        this.mapArea.width = "100%";
        this.mapArea.height = Math.round(mapEl.offsetWidth * 0.57) + "px";
        this.mapArea.maxWidth = Math.round(mapEl.offsetHeight * 1.53) + "px";
        this.mapArea.maxHeight = Math.round(mapEl.offsetHeight * 0.88) + "px";
        this.mapContainer.backgroundSize =
          Math.round(areaEl.offsetWidth * 1.952) + "px";

        if (mapEl.offsetWidth < 800 || mapEl.offsetHeight < 456) {
          this.mapArea.position = "absolute";
          this.mapArea.width = "800px";
          this.mapArea.height = "456px";
          this.mapArea.maxWidth = "none";
          this.mapArea.maxHeight = "none";
          this.mapContainer.backgroundSize = "1562px";

          if (mapEl.offsetWidth < 800 && mapEl.offsetHeight < 456) {
            this.mapArea.top = "0";
            this.mapArea.left = "0";
            this.mapArea.transform = "none";
            this.mapContainer.overflowX = "scroll";
            this.mapContainer.overflowY = "scroll";
          } else if (mapEl.offsetWidth < 800 && mapEl.offsetHeight > 456) {
            this.mapArea.top = "50%";
            this.mapArea.left = "0";
            this.mapArea.transform = "translateY(-50%)";
            this.mapContainer.overflowX = "scroll";
            this.mapContainer.overflowY = "visible";
          } else if (mapEl.offsetWidth > 800 && mapEl.offsetHeight < 456) {
            this.mapArea.top = "0";
            this.mapArea.left = "50%";
            this.mapArea.transform = "translateX(-50%)";
            this.mapContainer.overflowX = "visible";
            this.mapContainer.overflowY = "scroll";
          }
        } else {
          this.mapArea.width = "100%";
          this.mapArea.height = Math.round(mapEl.offsetWidth * 0.57) + "px";
          this.mapArea.overflow = "visible";
          this.mapArea.maxWidth = Math.round(mapEl.offsetHeight * 1.53) + "px";
          this.mapArea.maxHeight = Math.round(mapEl.offsetHeight * 0.88) + "px";
          this.mapArea.position = "absolute";
          this.mapArea.top = "50%";
          this.mapArea.left = "50%";
          this.mapArea.transform = "translate(-50%, -50%)";
          this.mapContainer.backgroundSize =
            Math.round(areaEl.offsetWidth * 1.952) + "px";
          this.mapContainer.overflowX = "visible";
          this.mapContainer.overflowY = "visible";
        }
      }
    },
    createMap() {
      // Set initial properties.
      gsap.set(".map-container", {
        opacity: 1,
        scale: 1,
      });
      gsap.set(".next-img", {
        opacity: 1,
        scale: 1,
      });
      gsap.set(".heading", {
        opacity: 0,
        x: "-=500",
      });
      gsap.set(".legend", {
        x: "+=500",
      });
      this.t1 = gsap.timeline();
      // Heading and Legend now animate in at the same time.
      // Shifted to be ahead of pin animations.
      this.t1.to(".heading", {
        duration: 0.5,
        opacity: 1,
        x: 0,
        ease: "back.out(1.5)",
      });
      this.t1.to(
        ".legend",
        {
          duration: 0.5,
          opacity: 1,
          x: 0,
          ease: "back.out(1.5)",
        },
        "<"
      );

      // One by one pin animations
      let pinAnim = {
        duration: 0.3,
        opacity: 0,
        scale: 1,
      };
      // Can also be adjusted to all pop up at the same time using "<".
      this.pins.forEach((pin, index) => {
        gsap.set(`.pin${index}`, { opacity: 1 });
        this.t1.from(`.pin${index}`, pinAnim, "-=.1");
      });
      this.mapSize();
    },
    pinClick(e) {
      let pinuri = encodeURI(e.pin.pin_link[0].value.name_slug);
      if (
        e.pin.pin_link[0].field.name === "link_url" &&
        e.pin.pin_link[0].value != ""
      ) {
        window.open(e.pin.pin_link[0].value, "_blank");
      } else if (e.pin.pin_link[0].field.name === "link_map") {
        let zoom = gsap.timeline();

        this.pins.forEach((pin, index) => {
          zoom.to(
            `.pin${index}`,
            {
              duration: 0.2,
              opacity: 0,
              scale: 1.2,
            },
            "-=.14"
          );
        });
        zoom.to(
          ".legend",
          {
            duration: 0.3,
            opacity: 0,
            x: "+=500",
          },
          "-=.6"
        );
        zoom.to(
          ".heading",
          {
            duration: 0.6,
            opacity: 0,
            x: "-100",
            onComplete: () =>
              (this.nextMap = this.maps.find(
                (x) => x._id == e.pin.pin_link[0].value._id
              )),
          },
          "<"
        );
        zoom.from(
          ".next-img",
          {
            duration: 1,
            scale: 0.5,
            opacity: 1,
          },
          "-=.5"
        );

        zoom
          .to(
            ".map-container",
            {
              duration: 0.5,
              opacity: 0,
              scale: 3,
            },
            "-=.5"
          )
          .then(() => {
            this.$router.push({ path: `/map/${pinuri}` });
          });
      } else if (e.pin.pin_link[0].field.name === "link_product_group") {
        this.$router.push({ path: `/productgroup/${pinuri}` });
      } else if (e.pin.pin_link[0].field.name === "link_product_model") {
        this.$router.push({ path: `/productmodel/${pinuri}` });
      }
    },
    createBreadcrumb() {
      const breadcrumbs = [
        {
          name: this.currentMap.name,
          slug: this.currentMap.name_slug,
          route: "map",
        },
      ];

      const recurse = (name) => {
        var maps = this.$store.state.maps;
        maps.forEach((m) => {
          m.map_pins.forEach((p) => {
            var p_display = (((p.value.pin_link || {})[0] || {}).value || {}).name;

            if (p_display == name) {
              breadcrumbs.push({
                name: m.name,
                slug: m.name_slug,
                route: "map",
              });
              recurse(m.name);
            }
          });
        });
      };

      recurse(this.currentMap.name);

      this.SET_BREADCRUMBS(breadcrumbs.reverse());
      //end Breadcrumbs
    },
    logoClick(e) {
      window.open(encodeURI(e.link), "_blank");
    },
    async buildMap() {
      await this.$store.dispatch("GET_MAP");

      this.$store
        .dispatch("SET_CURRENT_MAP", this.name)
        .then(
          () => {
          if( !this.currentMap ){
            router.push('/404')
          }
          }

        )
        .then(this.setMapAttr())
        .then(this.setPreviewMapImg())
        .then(this.setCurrentMapImg())
        .then(() => {
          this.createMap();
          this.windowResize();
          this.mapSize();
          this.setPins();
          this.setLegend();
          this.setLegendTitle();
          this.setVendorLogos();
          this.createBreadcrumb();
        });

    },
  },
  mounted() {
    window.addEventListener("load", this.windowResize);
    this.$nextTick(() => {
      window.addEventListener("resize", this.windowResize);
      window.addEventListener('resize', this.mapSize)

    });
    this.$nextTick(() => {
      this.fetchImage();
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
    window.removeEventListener("resize", this.mapSize );
  },
};
</script>

<template>
  <div id="map" class="map d-flex align-center justify-center" ref="map">
    <div
      class="map-container"
      :style="[mapContainer, { backgroundImage: `url(${asycImg.src})` }]"
      :data-state="imageState"
    >
      <div id="map-area" class="map-area" ref="map-area" :style="mapArea">
        <Heading
          v-if="!isDefault"
          class="heading"
          :colorTheme="currentMap.color_theme"
          :name="currentMap ? currentMap.name : ''"
          :icon="currentMap ? currentMap.icon : ''"
          :mapTitle="currentMap.map_title"
        >
        </Heading>
        <Pin
          :pin="pin"
          :mapData="currentMap"
          :class="[`pin${index}`]"
          :windowSize="windowSize"
          :mapHeight="mapArea.height"
          :isDefault="isDefault"
          class="pincontainer"
          v-for="(pin, index) in pins"
          :key="pin.pin_label"
          @click="pinClick"
        >
        </Pin>
        <LogoPin
          v-for="(logo, index) in vendorLogos"
          :key="index"
          :logo="logo"
          @click="logoClick"
        ></LogoPin>
      </div>
      <!-- end .map-area -->
      <div class="alt-map-images">
        <img
          v-if="previewMapImg"
          class="map-preview-img"
          :class="imageState"
          :src="previewMapImg"
        />
      </div>
    </div>
    <!-- end .map-container -->

    <MapLegend
      class="legend"
      v-if="legend.length > 0"
      @click="pinClick"
      :legendTitle="legendTitle"
      :pins="legend"
      :colorTheme="this.currentMap.color_theme"
    ></MapLegend>
  </div>
</template>

<style scoped lang="scss">
.loader {
  position: absolute;
  width: 100%;
}
.heading {
  opacity: 0;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto 0;
  background-color: lightgrey;
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 195.2%;
  background-attachment: local;
  background-position: center;
}

.map-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 57vw;
  max-height: 88vh;
  max-width: 153vh;
  z-index: 2;
}

.map-img {
  position: absolute;
  width: 195.2%;
}

.alt-map-images {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.map-preview-img,
.next-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(8px);
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 1;

  &.loaded {
    opacity: 0;
  }
}

.pincontainer {
  z-index: 10;
}

.current-img {
  z-index: 2;
}
</style>
