<script>
import gsap from "gsap";
import { mapState } from "vuex";

export default {
  name: "Pin-Icon",
  data(){
    return {
      baseWidth: 16
    }
  },
  props: [
    "pin",
    "name",
    "isRight",
    "isTop",
    "isLeft",
    "gradient",
    "mapData",
    "textColor",
    "mapHeight",
    "isDefault",
    "isStart",
    "labelWidth"
  ],
  methods: {
    over(e) {
      gsap.set(e.currentTarget, {
        zIndex: 10,
        transformOrigin: "50% 0",
      });
      gsap.to(e.currentTarget, {
        duration: 0.2,
        scale: 1.1,
        ease: "back.out(1.5)",
      });
    },
    out(e) {
      gsap.set(e.currentTarget, {
        zIndex: 9,
        transformOrigin: "50% 0",
      });
      gsap.to(e.currentTarget, {
        duration: 0.2,
        scale: 1,
      });
    },
    click(e) {
      this.$emit("click", { e, pin: this.pin });
    },
  },
  computed: {
    ...mapState([ "secondaryColor"]),
    icon() {
      return this.pin.icon || this.mapData.icon;
    },
    lineStyle() {
      if (this.pin.yoffset) {
        let bottom;
        let offset = Math.round(
          (this.pin.yoffset / 100) * parseInt(this.mapHeight, 10)
        );        
        
        if (this.pin.yoffset < 0) {
          bottom = `${offset * .7}px`;
        } else {
          bottom = 0;
        }       
        
        return {
          background:
            this.name === "inside" ? `${this.color_theme.color1}` : "#fff;",
          bottom,
          height: `${Math.abs(offset * .7)}px`,
          // height of the line is a percentage of the mapHeight.
          // mapHeight is a minimum of 57vw and grows based on window width.
          // When reducing the line's height the bottom and labelOffset also needs to be adjusted.
        };
      } else {
        return {
          background:
            this.name === "inside" ? `${this.color_theme.color1}` : "#fff;",
        };
      }
    },
    labelOffset() {
      if (this.pin.yoffset) {
        let offset = -Math.round(
          (this.pin.yoffset / 100) * parseInt(this.mapHeight, 10)
        );

        return `calc( ${(offset * .7) - 32}px)`;
        // labelOffset matches lineStyle's .7.
        // Take an additional 32px, half the height of the label. 
      } else {
        return "80px";
      }
    },
    color_theme() {
      return this.pin.color_theme || this.mapData.color_theme;
    },
    labelColors(){
      let labelColors = {
        icon: "",
        iconBg: "",
        label: "",
        labelBg: ""
      }
      if( this.isDefault ){
        labelColors.icon = "#ffffff",
        labelColors.iconBg = this.gradient,
        labelColors.label = this.secondaryColor.color2,
        labelColors.labelBg = "#ffffff"
      } else {
        labelColors.icon = this.color_theme.color2,
        labelColors.iconBg = "#ffffff",
        labelColors.label = this.color_theme.textcolor,
        labelColors.labelBg = this.gradient
      }
      return labelColors;
    }
  },

};
</script>

<template>
  <div
    class="pin ICON_PIN"
    :style="{ top: `${pin.y}%`, left: `${pin.x}%` }"
    @mouseenter="over"
    @mouseleave="out"
    @click="click"
    :class="[isRight ? 'pinleft' : '', isLeft ? 'pinright' : '']"
  >
    <div class="pin-label" :style="{ top: labelOffset }">
      <v-btn elevation="1
      " large class="icon" v-if="icon != ''" fab :style="{ background: labelColors.iconBg }" ><v-icon :color="labelColors.icon">{{ icon }}</v-icon></v-btn>
      <span
        :style="{ 
          color: labelColors.label, 
          background: labelColors.labelBg,
          maxWidth: `${baseWidth * labelWidth}em` 
          }"
        class="label"
        >{{ pin.pin_label }}</span
      >
    </div>
    <div :style="lineStyle" class="line" />

    <div
      v-if="isStart"
      :style="{ background: `${gradient}` }"
      class="pin-dot start-dot"
    >
      <span :style="{ background: `${gradient}` }" class="pin-halo"> </span>
      <span class="pin-dot-label">Start Here</span>
    </div>
    <div v-else :style="{ background: `${gradient}` }" class="pin-dot">
      <span :style="{ background: `${gradient}` }" class="pin-halo"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
@function get-vw($target) {
  $vw-context: (1000 * 0.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
.pin {
  position: absolute;
  width: 1px;
  height: 1px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.pin:hover {
  z-index: 100;
}

.pin-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: -140px;

 .icon {
   z-index: 5;
 }

  .label {
    flex: 1 0 auto;
    margin-left: -1em;
    background-color: #fff;
    color: #fff;
    font-weight: bold;
    font-size: calc(14px + .5vh);
    display: block;
    line-height: 1;
    padding: 0.4em 1em 0.4em 1.5em;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    -webkit-box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
    -moz-box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
    box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
    z-index: 1;
    max-width: 16em;
    text-align: left;
    @media (max-width: 1028px) {
      font-size: .875rem;
    }
    @media (max-height: 592px) {
      font-size: .875rem;
    }

  }
}
.v-btn--fab.v-size--large {
  height: 54px;
  width: 54px;
}
.line {
  width: 3px;
  height: 120px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  -webkit-box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
  -moz-box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
  box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
}

.pin-dot {
  background-color: #00af3e;
  min-width: 14px;
  min-height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;

  .pin-halo {
    content: "";
    width: 250%;
    height: 250%;
    border-radius: 50%;
    opacity: 0.4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.pin.pinleft {
  .pin-label {
    justify-content: flex-end;
    left: auto;
    right: -1em;
  }
}

.pin.pinright {
  .pin-label {
    justify-content: flex-start;
    left: -1em;
    right: auto;
    margin-left: -1.5em;
  }
}

.pin-inside {
  .pin-dot {
    min-width: 0;
    min-height: 0;
    width: 12px;
    height: 12px;

    .pin-halo {
      width: 300%;
      height: 300%;
    }
  }
}

.pin-dot.start-dot {
  background-color: #00af3e;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .pin-halo {
    content: "";
    width: 120%;
    height: 120%;
    border-radius: 50%;
    opacity: 0.4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .pin-dot-label {
    color: white;
    opacity: 1;
    z-index: 12;
    font-weight: bold;
    font-size: 1em;
    font-size: calc(12px + 0.5vw);
    white-space: nowrap;
    padding: 0 0.5em;
  }
}
</style>
