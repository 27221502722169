<script>
  import gsap from 'gsap'

  export default {
    name: 'Pin-Point',
    data() {
      return {
        baseWidth: 8 //in (em) units
      }
    },
    props: [
      'pin',
      'name',
      'isRight',
      'isTop',
      'isLeft',
      'gradient',
      'isDefault',
      'labelWidth'
    ],
    methods: {
      over(e) {
        gsap.set(e.currentTarget, {
          zIndex: 10,
          transformOrigin: '50% 0',
        })
        gsap.to(e.currentTarget, {
          duration: .2,
          scale: 1.1,
          ease: 'back.out(1.5)',
        })
      },
      out(e) {
        gsap.set(e.currentTarget, {
          zIndex: 9,
          transformOrigin: '50% 0',
        })
        gsap.to(e.currentTarget, {
          duration: .2,
          scale: 1,
        })
      },
      click(e) {
        this.$emit('click', { e, pin: this.pin })
      },
    },
  }
</script>

<template>
  <div
    class="pin pin-point POINT-PIN"
    :style="{ top: `${ pin.y }%`, left:  `${ pin.x }%` }"
    @mouseenter="over"
    @mouseleave="out"
    @click="click"
    :class="[isRight ? 'pinleft' : '', isLeft ? 'pinright' : '', isTop ? 'pinbottom' : '']"
  >
    <div class="pin-label" >
      <span
       class="label" :style="{ width: `${baseWidth * labelWidth}em` }">{{ pin.pin_label }}</span>
    </div>
    <div
    :style="{background: `${gradient}`}"
    class="pin-dot">
      <span
      :style="{background: `${gradient}`}"
      class="pin-halo"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @function get-vw($target) {
    $vw-context: (1000*.01) * 1px;
    @return ($target/$vw-context) * 1vw;
  }
  
  .pin {
    position: absolute;
    width: 1px;
    height: 1px;
    cursor: pointer;
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  .pin:hover {
    z-index: 100;
  }

  .pin-label {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: auto;
    z-index: 10;
    position: absolute;
    bottom: -40px;

    .label {
      flex: 1 0 auto;
      color: #fff;
      font-weight: bold;
      font-size: calc(12px + .35vh);
      display: block;
      line-height: 1.15;
      padding: .5em 1em .5em 1.5em;
      border-radius: 1em;
      z-index: 1;
      text-shadow: 0 2px 5px rgb(30, 30, 30), 0 1px 3px rgb(20, 20, 20) ;
    @media (max-width: 1028px) {
      font-size: .75rem;
    }
    @media (max-height: 592px) {
      font-size: .75rem;
    }

    }
  }

  span.label {
    text-align: center;
    vertical-align: top;
    margin: 0px;
    padding: 0px !important;
    width: 150px;
    height: auto;
    position: absolute;
    top: -21px;
  }

 .pin-dot {
   background-color: #00af3e;
   width: 14px;
   height: 14px;
   border-radius: 50%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: #fff;
   @media ( max-width: 800px ){
     width: 10px;
     height: 10px;
   }

  .pin-halo {
    content: "";
    width: 250%;
    height: 250%;
    border-radius: 50%;
    opacity: .4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
 }

 .pin.pinleft {
   .pin-label {
     justify-content: flex-end;
     left: auto;
     right: -1em;
   }
 }

 .pin.pinright {
   .pin-label {
     justify-content: flex-start;
     left: -1em;
     right: auto;
     margin-left: -15px;
   }

 }

 .pin-inside {
   .pin-dot {
     min-width: 0;
     min-height: 0;
     width: 12px;
     height: 12px;

     .pin-halo {
       width: 300%;
       height: 300%;
     }
   }
 }

</style>
