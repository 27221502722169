<script>

export default {
  name: "LegendCategory",
  props: ['pin', 'colortheme'],
  methods: {
    click(e) {
      console.log("this pin", this.pin);
      this.$emit('click', { e, pin: this.pin })
    },
  }
}

</script>

<template>
    <div class="legend-category" @click="click">
      <div class="legend-icon-container">
        <div class="legend-icon" >
          <v-icon :style="{ color: colortheme.color2 }">{{ pin.icon }}</v-icon>
        </div>
      </div>
      <h3 class="legend-label" >{{ pin.legend_name }}</h3>
    </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/sass/theme.scss';

.legend-category {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.legend-icon-container {
  width: 1.733em;
  height: 1.733em;
  width: calc( 2.375em + 1vw );
  height: calc( 2.375em + 1vw );
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  box-shadow: 0 10px 8px -6px rgba(0, 0, 0, .5);
  @media ( max-width: 800px ){
   width: 2.6em;
   height: 2.6em;
  }
}

.legend-icon {
  border-radius: 50%;
  display: inline-block;
  background-size: cover;
  background-position: center;
  
  .v-icon {
    font-size: 1.5em; //fallback sizing in case calc does not work.
    font-size: calc( .875em + .875vw );
    @media ( max-width: 800px ){
      font-size: 1.5em;
    }
  }
}

.legend-label {
  flex: 1 0 auto;
  margin-left: -1em;
  background-color:#fff;
  color: $black;
  font-weight: medium;
  font-size: 1.125em; // fallback sizing in case calc does not work.
  font-size: calc( 1em + .0625vw );
  display: block;
  line-height: 1;
  padding: .575em 1em .575em 1.5em;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .5);
  z-index: 1;
  @media ( max-width: 800px ){
    font-size: 0.75em;
  }
}

</style>
