<script>
  import gsap from 'gsap'

  export default {
    name: 'Pin-Gradient',
    props: [
      'pin',
      'name',
      'isRight',
      'isTop',
      'isLeft',
      'gradient',
      'mapData',
      "isDefault"
    ],
    methods: {
      over(e) {
        gsap.set(e.currentTarget, {
          zIndex: 10,
          transformOrigin: '50% 0',
        })
        gsap.to(e.currentTarget, {
          duration: .2,
          scale: 1.1,
          ease: 'back.out(1.5)',
        })
      },
      out(e) {
        gsap.set(e.currentTarget, {
          zIndex: 9,
          transformOrigin: '50% 0',
        })
        gsap.to(e.currentTarget, {
          duration: .2,
          scale: 1,
        })
      },
      click(e) {
        this.$emit('click', { e, pin: this.pin })
      },
    },
    computed: {
      color_theme(){
        return this.mapData.color_theme;
      },
      lineStyle() {
        if(this.pin.yOffset) {
          console.log(this.pin.yOffset)
          console.log(parseFloat(this.pin.yOffset))
          let bottom
          let offset = parseFloat(this.pin.yOffset)

          if(offset && offset < 0) {
            bottom = `${ offset }px`
          }
          else {
            bottom = 0
          }
          return {
            background: name === 'inside' ? `${this.color_theme.color1}` : '#fff;',
            height: `${ Math.abs(this.pin.yOffset) }px`,
            bottom,
          }
        }
        else {
          return {
            background: name === 'inside' ? `${this.color_theme.color1}` : '#fff;',
          }
        }

      },
    }
  }
</script>

<template>
  <div
    class="pin GRAD-PIN"
    :style="{ top: `${ pin.y }%`, left:  `${ pin.x }%` }"
    @mouseenter="over"
    @mouseleave="out"
    @click="click"
    :class="[isRight ? 'pinleft' : '', isLeft ? 'pinright' : '', isTop ? 'pinbottom' : '']"
  >
    <div class="pin-label" :style="{ top: `${ -pin.yOffset - 20 }px`}" >
      <span
      :style="{ color: color_theme.textcolor, background: `${gradient}`}"
       class="label">{{ pin.pin_label }}</span>
    </div>
    <div
      :style="lineStyle"
      class="line"></div>
    <div
    :style="{background: `${gradient}`}"
    class="pin-dot">
      <span
      :style="{background: `${gradient}`}"
      class="pin-halo"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.testpin{
  position: absolute;
  overflow: visible;
  display: block;
  width: 1px;
  height: 1px;
  background: blue;
  &::before {
        content: "";
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: blue;
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: orange;
    z-index: -1;
  }
}

@function get-vw($target) {
  $vw-context: (1000*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

.pin {
  position: absolute;
  width: 1px;
  height: 1px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.pin:hover {
  z-index: 100;
}

.pin-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: auto;
  z-index: 10;
  position: absolute;
  top: -140px;

  .icon {
    flex: 0 0 auto;
    z-index: 5;
    width: 65px;
    height: 65px;
    background-color: #fff;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 10px 8px -6px rgba(0, 0, 0, .5);
  }

  .label {
    flex: 1 0 auto;
    background-color:#fff;
    color: #fff;
    font-weight: bold;
    font-size: calc(14px + .7vh);
    display: block;
    line-height: 1.15;
    padding: .4em 1em .4em 1em;
    border-radius: 1em;
    -webkit-box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
    -moz-box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
    box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
    z-index: 1;
        @media (max-width: 800px) {
      font-size: 1.125rem;
    }
    @media (max-height: 800px) {
      font-size: 1.125rem;
    }
  }
}

.line {
  width: 3px;
  height: 120px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  -webkit-box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
  -moz-box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
  box-shadow: 0 3px 5px rgb(30, 30, 30), 0 1px 3px rgb(50, 50, 50);
}

.pin-dot {
  background-color: #00af3e;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;

  .pin-halo {
    content: "";
    width: 180%;
    height: 180%;
    border-radius: 50%;
    opacity: .4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

  .pin.pinbottom {

   .pin-label {
      bottom: auto;
      top: 100px;
    }

    .line {
      bottom: auto;
      top: 0;
    }
  }

 .pin.pinleft {
   .pin-label {
     justify-content: flex-end;
     left: auto;
     right: -1em;

   }
 }

  .pin.pinright {
    .pin-label {
      justify-content: flex-start;
      left: -1em;
      right: auto;
      margin-left: -15px;
    }
  }

  .pin-inside {
    .pin-dot {
      min-width: 0;
      min-height: 0;
      width: 12px;
      height: 12px;

      .pin-halo {
        width: 300%;
        height: 300%;
      }
    }
  }

</style>
