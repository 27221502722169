<script>
import gsap from "gsap";
import Gradient from "@/components/pins/Gradient.vue";
import Icon from "@/components/pins/Icon.vue";
import Logo from "@/components/pins/Logo.vue";
import Point from "@/components/pins/Point.vue";

export default {
  name: "Pin",
  data() {
    return {
      rightThresh: 80,
      yThresh: 30,
      leftThresh: 20,
    };
  },
  props: ["pin", "name", "mapData", "mapHeight", "isDefault"],
  components: {
    Icon,
    Gradient,
    Logo,
    Point
  },
  computed: {
    isRight() {
      return this.pin.x > this.rightThresh;
    },
    isTop() {
      return this.pin.y < this.yThresh;
    },
    isLeft() {
      return this.pin.x < this.leftThresh;
    },
    gradient(){
      let pincolors;
        if ( this.pin.color_theme && this.pin.color_theme != 'null' ) {
         pincolors = this.pin.color_theme
        } else {
          pincolors = this.mapData.color_theme;
        }
        return pincolors.color2 ? `linear-gradient(15deg, ${pincolors.color2}, ${pincolors.color1})` : pincolors.color1;  
    },
    style() {
      let pinStyle = this.pin.style || this.mapData.style;
      pinStyle === "Start" ? pinStyle = "Icon" : pinStyle;
      return pinStyle;
    },
    textColor(){
      let thiscolor;

        if( this.pin.color_theme && this.pin.color_theme != 'null' ){
         thiscolor = this.pin.color_theme.textcolor
      } else {
        thiscolor = this.mapData.color_theme.textcolor;
      }
      return thiscolor;

    },
    isStart(){
      let pinStyle = this.pin.style || this.mapData.style;
      return pinStyle === "Start";
    },
    labelWidth(){
     if( !this.pin.labelWidth ){
       return 1;
     }
     let absval = Math.abs( this.pin.labelWidth/100 );
     return this.pin.labelWidth < 0 ? 1 - absval : absval + 1;
    }
  },
  methods: {
    click(e) {
      this.$emit("click", { e, pin: this.pin });
    },
  },
};
</script>

<template>
  <component
    :is="style"
    @click="click"
    :name="name"
    :pin="pin"
    :mapData="mapData"
    :isRight="isRight"
    :isLeft="isLeft"
    :isTop="isTop"
    :gradient="gradient"
    :textColor="textColor"
    :mapHeight="mapHeight"
    :isDefault="isDefault"
    :isStart="isStart"
    :labelWidth="labelWidth"
  />
</template>
