<script>
import { mapState } from 'vuex'

export default {
  props: [
    'name',
    'icon',
    'colorTheme',
    'mapTitle'
  ],
  computed: {
    ...mapState(['globalColorTheme']),
    gradient(){
      let headcolors = this.colorTheme || this.globalColorTheme;
      return  headcolors.color2 ? `linear-gradient(15deg, ${headcolors.color2}, ${headcolors.color1})` : headcolors.color1
    },
    headTextColor(){
      return this.colorTheme.textcolor || this.globalColorTheme.textcolor;
    },
    globalGradient(){
        return  this.globalColorTheme.color2 ? `linear-gradient(15deg, ${this.globalColorTheme.color2}, ${this.globalColorTheme.color1})` : this.globalColorTheme.color1
    },
    mapHeading(){
      console.log('maptitles', this.mapTitle);
      return this.mapTitle && this.mapTitle != 'null' ? this.mapTitle : this.name;
    }
  }
}
</script>

<template>
  <div class="page-header">
    <div class="header-wrap">
      <div class="header-icon gradientbg" :style="{ backgroundImage: globalGradient }"><v-icon :style="{ color: globalColorTheme.textcolor }">{{icon}}</v-icon></div>
      <h1 class="header-title gradientbg" :style="{ backgroundImage: globalGradient, color: globalColorTheme.textcolor }">{{ mapHeading }}</h1>
    </div>
    <div class="header-subtitle" v-if="mapTitle && mapTitle !== 'null'"  >
      <h2 :style="{ backgroundImage: gradient, color: headTextColor }">{{name}}</h2>
      </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/sass/theme.scss';

  .page-header {
    position: absolute;
    width: fit-content;
    top: 0;
    left: 0;
    padding: 0;

    z-index: 100;
    .header-wrap {
      display: flex;
      align-items: center;
      margin-left: 3.1rem;
    }
    .gradientbg {
      background-image: $praxair-darkgreen-gradient
    }
    .header-icon {
      width: 4.375em;
      height: 4.375em;
      width: calc( 3.54em + 1vw );
      height: calc( 3.54em + 1vw );
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
      position: relative;
      @media ( max-width: 800px ){
        width: 4em;
        height: 4em;
      }

      .v-icon {
        font-size: 2.75em;
        font-size: calc( 1.91em + 1vh);
        @media ( max-width: 800px ){
          font-size: 2.375em;
        }
      }

      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 10px 8px -6px rgba(0, 0, 0, .5);
        z-index: -10;
      }
    }
    .header-title {
      margin-left: -1em;
      background-color:#00AF3F;
      color: #fff;
      font-weight: bold;
      font-size: 2em;
      font-size: calc( 1.16em + 1vh );
      padding: 0 .75em 0 1.25em;
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
      box-shadow: 0 3px 3px rgba(0, 0, 0, .5);
      @media (max-width: 800px) {
        font-size: 1.5rem;
      }
      @media (max-height: 800px) {
        font-size: 1.5rem;
      }
    }
    .header-subtitle {
      text-align: left;
      margin-left: 4.5em;
      h2 {
        font-size: 1.375em;
        font-size: calc(0.5em + 1vw );
        display: inline-block;
        border-radius: 1em;
        padding: .125em .75em;
        margin-left: 2em;
        @media( max-width: 800px ){
          font-size: 1em;
        }
      }
    }
  }
</style>
