<script>

import LegendCategory from "@/components/LegendCategory"

export default {
  name: "Legend",
  props: ['pins', 'legendTitle', 'colorTheme'],
  components: {
    LegendCategory,
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    },
  }
}

</script>

<template>
  <div class="legend">
    <h2>{{legendTitle}}</h2>
    <LegendCategory @click="click" v-for="pin in pins" :key="pin._id" :pin="pin" :colortheme="colorTheme" ></LegendCategory>
    <div class="legend-background"></div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/sass/theme.scss';

    .legend {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 10px;
      padding: 14px 46px 14px 28px;
      z-index: 3;
      color: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;

      h2 {
        font-size: 1.25em;
        font-size: calc( .25em + 1vw );
        line-height: 1.1;
        margin-right: 10px;
        @media ( max-width: 800px ){
          font-size: .75em;
        }
      }
    }

    .legend-background {
      content: "";
      background-color: rgba(90, 90, 90, .7);
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }

</style>
